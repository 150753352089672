<template>
    <div class="site-container">
        <div style="height: 75px;background-color: #3C4DD7">
            <img src="../../assets/image/VIA_logo.png" alt="" style="margin-top: 18px;height: 40px;margin-left: 60px" >
        </div>
        <div class="content-sidebar-wrap">
            <main class="content" id="genesis-content">
                <article class="post-17196 page type-page status-publish has-post-thumbnail entry"
                         aria-label="Privacy Policy">
                    <header class="entry-header">
                        <h1 class="entry-title" style="text-align: center;margin-top: 40px">Privacy Policy</h1>
                    </header>
                    <div class="entry-content">
                        <p>This is the privacy notice of VIA Technologies, Inc. In this document, &#8220;we&#8221;,
                            &#8220;our&#8221;, or &#8220;us&#8221; refer to VIA Technologies, Inc.</p>
                        <p>We are company number 86870786 registered in Taiwan, listed under number 2388 at Taiwan Stock
                            Exchange.
                        </p>
                        <p>Our headquarters are at 8F, 533 Zhongzheng Rd, Taipei, New Taipei City 231, Taiwan.</p>
                        <hr/>
                        <h2 style="text-align: center;">Introduction</h2>
                        <hr/>
                        <ol>
                            <li>This is a notice to inform you of our policy about all information that we record about
                                you. It sets
                                out the conditions under which we may process any information that we collect from you,
                                or that you
                                provide to us. It covers information that could identify you (“personal information”)
                                and information
                                that could not. In the context of the law and this notice, “process” means collect,
                                store, transfer,
                                use or otherwise act on information.
                            </li>
                            <li>We regret that if there are one or more points below with which you are not happy, your
                                only
                                recourse is to leave our website immediately.
                            </li>
                            <li>We take seriously the protection of your privacy and confidentiality. We understand that
                                all
                                visitors to our website are entitled to know that their personal data will not be used
                                for any purpose
                                unintended by them, and will not accidentally fall into the hands of a third party.
                            </li>
                            <li>We undertake to preserve the confidentiality of all information you provide to us, and
                                hope that you
                                reciprocate.
                            </li>
                            <li>Our policy complies with EU General Data Protection Regulation (GDPR).</li>
                            <li>We would like to inform you about your rights and our obligations to you in regards to
                                the
                                processing and control of your personal data.
                            </li>
                            <li>Except as set out below, we do not share, or sell, or disclose to a third party, any
                                information
                                collected through our website.
                            </li>
                        </ol>
                        <hr/>
                        <h2 style="text-align: center;">The bases on which we process information about you</h2>
                        <hr/>
                        <p>The law requires us to determine under which of six defined bases we process different
                            categories of
                            your personal information, and to notify you of the basis for each category.</p>
                        <p>If a basis on which we process your personal information is no longer relevant then we shall
                            immediately stop processing your data.</p>
                        <p>If the basis changes then if required by law we shall notify you of the change and of any new
                            basis
                            under which we have determined that we can continue to process your information.</p>
                        <h3>1. Information we process because we have a contractual obligation with you</h3>
                        <p>When you create an account on our website, buy a product or service from us, or otherwise
                            agree to our
                            terms and conditions, a contract is formed between you and us.</p>
                        <p>In order to carry out our obligations under that contract we must process the information you
                            give us.
                            Some of this information may be personal information.</p>
                        <p>We may use it in order to:</p>
                        <ul>
                            <li>Verify your identity for security purposes</li>
                            <li>Sell products to you</li>
                            <li>Provide you with our services</li>
                            <li>Provide you with suggestions and advice on products, services and how to obtain the most
                                from using
                                our website
                            </li>
                            <li>Send periodic emails regarding your order or other products and services</li>
                            <li>Follow up with you after correspondence by email or phone call</li>
                        </ul>
                        <p>We process this information on the basis that there is a contract between us, or that you
                            have
                            requested we use the information before we enter into a legal contract.</p>
                        <p>Additionally, we may aggregate this information in a general way and use it to provide class
                            information, for example to monitor our performance with respect to a particular service we
                            provide. If
                            we use it for this purpose, you as an individual will not be personally identifiable.</p>
                        <p>We shall continue to process this information until the contract between us ends or is
                            terminated by
                            either party under the terms of the contract.</p>
                        <h3>2. Information we process with your consent</h3>
                        <p>Through certain actions when otherwise there is no contractual relationship between us, such
                            as when
                            you browse our website or ask us to provide you more information about our business,
                            including our
                            products and services, you provide your consent to us to process information that may be
                            personal
                            information.</p>
                        <p>Wherever possible, we aim to obtain your explicit consent to process this information, for
                            example, by
                            asking you to agree to our use of cookies.</p>
                        <p>Sometimes you might give your consent implicitly, such as when you send us a message by
                            e-mail to which
                            you would reasonably expect us to reply.</p>
                        <p>Except where you have consented to our use of your information for a specific purpose, we do
                            not use
                            your information in any way that would identify you personally. We may aggregate it in a
                            general way and
                            use it to provide class information, for example to monitor the performance of a particular
                            page on our
                            website.</p>
                        <p>If you have given us explicit permission to do so, we may from time to time pass your name
                            and contact
                            information to selected associates whom we consider may provide services or products you
                            would find
                            useful.</p>
                        <p>We continue to process your information on this basis until you withdraw your consent or it
                            can be
                            reasonably assumed that your consent no longer exists.</p>
                        <p>You may withdraw your consent at any time by instructing us at <a
                                href="mailto:embedded@via.com.tw">embedded@via.com.tw</a>.</p>
                        <h3>3. Information we process for the purposes of legitimate interests</h3>
                        <p>We may process information on the basis there is a legitimate interest, either to you or to
                            us, of
                            doing so.</p>
                        <p>Where we process your information on this basis, we do after having given careful
                            consideration to:</p>
                        <ul>
                            <li>Whether the same objective could be achieved through other means</li>
                            <li>Whether processing (or not processing) might cause you harm</li>
                            <li>Whether you would expect us to process your data, and whether you would, in the round,
                                consider it
                                reasonable to do so
                            </li>
                        </ul>
                        <p>For example, we may process your data on this basis for the purposes of:</p>
                        <ul>
                            <li>Record-keeping for the proper and necessary administration of our online store <a
                                    href="http://www.viaembeddedstore.com/">http://www.viaembeddedstore.com/</a></li>
                            <li>Responding to unsolicited communication from you to which we believe you would expect a
                                response
                            </li>
                            <li>Protecting your interests where we believe we have a duty to do so</li>
                        </ul>
                        <h3>4.Information we process because we have a legal obligation</h3>
                        <p>We are subject to the law like everyone else. Sometimes, we must process your information in
                            order to
                            comply with a statutory obligation.</p>
                        <p>For example, we may be required to give information to legal authorities if they so request
                            or if they
                            have the proper authorisation such as a search warrant or court order.</p>
                        <p>This may include your personal information.</p>
                        <hr/>
                        <h2 style="text-align: center;">Specific uses of information you provide to us</h2>
                        <hr/>
                        <h3>5. Information relating to your method of payment</h3>
                        <p>Payment information is never taken by us or transferred to us either through our website or
                            otherwise.
                            Our employees and contractors never have access to it.</p>
                        <p>At the point of payment, you are transferred to a secure page on the website of PayPal. That
                            page may
                            be branded to look like a page on our website, but it is not controlled by us.</p>
                        <h3>6. Sending a message to our support team</h3>
                        <p>When you contact us, whether by telephone, through our website or by e-mail, we collect the
                            data you
                            have given to us in order to reply with the information you need.</p>
                        <p>We keep personally identifiable information associated with your message, such as your name
                            and email
                            address so as to be able to track our communications with you to provide a high quality
                            service.</p>
                        <hr/>
                        <h2 style="text-align: center;">Use of information we collect through automated systems when you
                            visit our
                            website</h2>
                        <hr/>
                        <h3>7. Cookies</h3>
                        <p>Cookies are small text files that are placed on your computer&#8217;s hard drive by your web
                            browser
                            when you visit any website. They allow information gathered on one web page to be stored
                            until it is
                            needed for use on another, allowing a website to provide you with a personalized experience
                            and the
                            website owner with statistics about how you use the website so that it can be improved.</p>
                        <p>Some cookies may last for a defined period of time, such as one day or until you close your
                            browser.
                            Others last indefinitely.</p>
                        <p>Your web browser should allow you to delete any you choose. It also should allow you to
                            prevent or
                            limit their use.</p>
                        <p>Our website uses cookies. They are placed by software that operates on our servers, and by
                            software
                            operated by third parties whose services we use.</p>
                        <p><em>When you first visit our website, we ask you whether you wish us to use cookies. If you
                            choose not
                            to accept them, we shall not use them for your visit except to record that you have not
                            consented to
                            their use for any other purpose.</em></p>
                        <p>We use cookies in the following ways:</p>
                        <ul>
                            <li>To keep you signed in our site</li>
                            <li>To record your answers to surveys and questionnaires on our site while you complete
                                them
                            </li>
                            <li>To help remember and process the items in the shopping cart</li>
                            <li>To keep track of advertisements</li>
                            <li>To compile aggregate data about site traffic and site interactions in order to offer
                                better site
                            </li>
                        </ul>
                        <p><strong>Third-party disclosure</strong></p>
                        <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable
                            Information.</p>
                        <p><strong>Third-party links</strong></p>
                        <p>We do not include or offer third-party products or services on our website.</p>
                        <p><strong>Google</strong></p>
                        <p>Google&#8217;s advertising requirements can be summed up by Google&#8217;s Advertising
                            Principles. They
                            are put in place to provide a positive experience for users. <a
                                    href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">https://support.google.com/adwordspolicy/answer/1316548?hl=en</a>
                        </p>
                        <p>We use Google AdSense Advertising on our website.</p>
                        <p>Google, as a third-party vendor, uses cookies to serve ads on our site. Google&#8217;s use of
                            the DART
                            cookie enables it to serve ads to our users based on previous visits to our site and other
                            sites on the
                            Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and
                            Content Network
                            privacy policy.</p>
                        <p>We have implemented the following:</p>
                        <ul>
                            <li>Remarketing with Google AdSense</li>
                            <li>Google Display Network Impression Reporting</li>
                            <li>Demographics and Interests Reporting</li>
                        </ul>
                        <p>We, along with third-party vendors such as Google and Facebook, use first-party cookies (such
                            as the
                            Google Analytics cookies and Facebook Pixel) to compile data regarding user interactions
                            with ad
                            impressions and other ad service functions as they relate to our website.</p>
                        <p><strong>Opting out</strong></p>
                        <p>Users can set preferences for how Google advertises to you using the Google Ad Settings page.
                            Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page
                            or by using
                            the Google Analytics Opt Out Browser add on.</p>
                        <h3>8. Personal identifiers from your browsing activity</h3>
                        <p>Requests by your web browser to our servers for web pages and other content on our website
                            are
                            recorded.</p>
                        <p>We record information such as your geographical location, your Internet service provider and
                            your IP
                            address. We also record information about the software you are using to browse our website,
                            such as the
                            type of computer or device and the screen resolution.</p>
                        <p>We use this information in aggregate to assess the popularity of the webpages on our website
                            and how we
                            perform in providing content to you.</p>
                        <p>If combined with other information we know about you from previous visits, the data possibly
                            could be
                            used to identify you personally, even if you are not signed in to our website.</p>
                        <h3>9. Our use of re-marketing</h3>
                        <p>Re-marketing involves placing a cookie on your computer when you browse our website in order
                            to be able
                            to serve to you an advert for our products or services when you visit some other
                            website.</p>
                        <p>We may use a third party to provide us with re-marketing services from time to time. If so,
                            then if you
                            have consented to our use of cookies, you may see advertisements for our products and
                            services on other
                            websites.</p>
                        <hr/>
                        <h2 style="text-align: center;">Disclosure and sharing of your information</h2>
                        <hr/>
                        <h3>10. Information we obtain from third parties</h3>
                        <p>Although we do not disclose your personal information to any third party, we sometimes
                            receive data
                            that is indirectly made up from your personal information from third parties whose services
                            we use.</p>
                        <p>No such information is personally identifiable to you.</p>
                        <h3>11. Data may be processed outside the European Union</h3>
                        <p>Our websites are hosted in the US, Europe, and Taiwan.</p>
                        <p>We may also use outsourced services in countries outside the European Union from time to time
                            in other
                            aspects of our business.</p>
                        <p>Accordingly data obtained within the EU or any other country could be processed outside the
                            European
                            Union.</p>
                        <p>For example, some of the software our website uses may have been developed in the United
                            States of
                            America or in Taiwan.</p>
                        <hr/>
                        <h2 style="text-align: center;">Access to your own information</h2>
                        <hr/>
                        <h3>12. Access to your personal information</h3>
                        <ul>
                            <li>At any time you may review or update personally identifiable information that we hold
                                about you.
                            </li>
                            <li>To obtain a copy of any information you may send us a request at <a
                                    href="mailto:embedded@via.com.tw">embedded@via.com.tw</a></li>
                            <li>After receiving the request, we will tell you when we expect to provide you with the
                                information.
                            </li>
                        </ul>
                        <h3>13. Removal of your information</h3>
                        <p>If you wish us to remove personally identifiable information from our website, you may
                            contact us at <a
                                    href="mailto:embedded@via.com.tw">embedded@via.com.tw</a> or follow the instructions
                            at the bottom of
                            each marketing email.</p>
                        <p>This may limit the service we can provide to you.</p>
                        <h3>14. Verification of your information</h3>
                        <p>When we receive any request to access, edit or delete personal identifiable information we
                            shall first
                            take reasonable steps to verify your identity before granting you access or otherwise taking
                            any action.
                            This is important to safeguard your information.</p>
                        <hr/>
                        <h2 style="text-align: center;">Other matters</h2>
                        <hr/>
                        <h3>15. Use of site by children</h3>
                        <p>We do not sell products or provide services for purchase by minors. If you are legally not an
                            adult,
                            you may purchase our products or services only with an adult supervision.</p>
                        <h3>16. Encryption of data sent between us</h3>
                        <p>We use Secure Sockets Layer (SSL) certificates to verify our identity to your browser and to
                            encrypt
                            any data you give us.</p>
                        <p>Whenever information is transferred between us, you can check that it is done so using SSL by
                            looking
                            for a closed padlock symbol or other trust mark in your browser’s URL bar or toolbar.</p>
                        <h3>17. How you can complain</h3>
                        <p>If you are dissatisfied with our privacy policy or would like to file a complaint, please
                            notify us by
                            email at embedded@via.com.tw. We will reach back to you as soon as possible. In case the
                            complaint
                            proves impossible to be resolved, we will work with the appropriate regulatory authorities,
                            including
                            local data protection bodies, to resolve the issue.</p>
                        <h3>18. Retention period for personal data</h3>
                        <p>Except as otherwise mentioned in this privacy notice, we keep your personal information only
                            for as
                            long as required by us:</p>
                        <ul>
                            <li>To provide you with the services you have requested;</li>
                            <li>To comply with other law, including for the period demanded by our tax authorities;</li>
                            <li>To support a claim or defence in court.</li>
                        </ul>
                        <h3>19. Compliance with the law</h3>
                        <p>Our privacy policy has been compiled so as to comply with the law of every country or legal
                            jurisdiction in which we aim to do business. If you think it fails to satisfy the law of
                            your
                            jurisdiction, we should like to hear from you.</p>
                        <p>However, ultimately it is your choice as to whether you wish to use our website.</p>
                        <h3>20. Review of this privacy policy</h3>
                        <p>We may update this privacy notice from time to time as necessary. The terms that apply to you
                            are those
                            posted here on our website on the day you use our website. We advise you to save a copy for
                            your
                            records.</p>
                        <p>If you have any question regarding our privacy policy, please <a
                                href="mailto:embedded@via.com.tw">contact us</a>.</p>
                        <hr/>
                        <h2 style="text-align: center;"><strong>Contacting Us</strong></h2>
                        <hr/>
                        <p>If there are any questions regarding this privacy policy, you may contact us using the
                            information
                            below.</p>
                        <p><a href="mailto:embedded@via.com.tw">embedded@via.com.tw</a></p>
                        <p>8F, 533 Zhongzheng Rd.,&nbsp;New Taipei City 231, Taiwan</p>
                        <p><a href="https://www.viatech.com/en/">https://www.viatech.com</a></p>
                        <hr/>
                        <h6 style="text-align: right;"><em>Last Edited on 2018-05-24</em></h6>
                    </div>
                </article>
            </main>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicyDetail"
    }
</script>

<style scoped>
    @import "../../assets/css/privacyPolicy.css";
</style>
